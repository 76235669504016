$enable-dark-mode:true;
$color-mode-type: media-query;

@import "../node_modules/bootstrap/scss/bootstrap";

.star-icon{
    color:gold;
    path {
        stroke-width: 1;
        stroke: goldenrod;
    }
}